@import "../../breakpoint";

.home1 {
  background-color: black;
  width: 100%;
  position: relative;
  .container {
    padding: 0;
    padding-top: 100px;
    padding-bottom: 150px;
    max-width: 1440px;
    .video {
      margin: 0 auto;
      video {
        width: 100%;
      }
      @include media("<=768px") {
        padding: 10px;
      }
    }
    .cork {
      position: absolute;
      top: 420px;
      left: 275px;
      width: 100px;
      z-index: 5;
      opacity: 1;
      img {
        max-width: 100%;
      }
      @include media("<=1024px") {
        display: none;
      }
    }
    @include media("<=768px") {
      padding-top: 50px;
    }
  }

  .custom-shape-divider-bottom-1632391273 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-bottom-1632391273 svg {
    position: relative;
    display: block;
    width: calc(140% + 1.3px);
    height: 240px;
    @include media("<=600px") {
      height: 140px;
    }
  }

  .custom-shape-divider-bottom-1632391273 .shape-fill {
    fill: #cdf9ff;
  }
  // .custom-shape-divider-bottom-1628566547 {
  //   position: absolute;
  //   bottom: -1px;
  //   left: 0;
  //   width: 100%;
  //   overflow: hidden;
  //   line-height: 0;
  //   svg {
  //     position: relative;
  //     display: block;
  //     width: calc(130% + 1.3px);
  //     height: 130px;
  //   }
  //   .shape-fill {
  //     fill: #cdf9ff;
  //   }
  // }
}
