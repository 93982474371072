@import "../breakpoint";

.modal-body {
  .react-tabs__tab-list {
    border-bottom: none;
    .react-tabs__tab {
      bottom: 10px;
      min-width: 100px;
      height: 40px;
      text-align: center;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      border: 1px solid transparent;
      &.react-tabs__tab--selected {
        background-color: #001a3a;
        color: #fff;
      }
    }
    .react-tabs__tab {
      border: 1px solid #001a3a;
      cursor: pointer;
      margin-right: 20px;
      &.custom_tab {
        border-radius: 32px;
        @include media("<=439px") {
          font-size: 14px;
          min-width: 80px;
        }
        @include media("<380px") {
          margin-right: 10px;
        }
      }
    }
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding-left: 5px;
  }
}
