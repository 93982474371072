@import "../breakpoint";
.header2 {
  background-color: #001a3a;
  .container {
    padding: 0;
    height: 170px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      width: 200px;
      height: 51px;
      img {
        max-width: 100%;
      }
    }
    .menu {
      ul {
        margin: 0;
        list-style: none;
      }
      li {
        list-style: none;
      }
      li {
        display: inline-block;
        margin-right: 66px;
        color: #fff;
        a {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          text-decoration: none;
          color: #fff;
          font-weight: 400;
          line-height: 19.5px;
          font-size: 16px;
          svg {
            display: none;
          }
          &.active {
            font-weight: 700;
            & > svg {
              display: block;
            }
          }
        }
      }
      @include media("<=1024px") {
        display: none;
      }
    }
    .menuIcon {
      display: inline-block;
      margin-right: 20px;
      @include media(">1024px") {
        display: none;
      }
    }
  }
}
