@import "../breakpoint";

.header {
  background-color: black;
  position: relative;
  .container {
    width: 100%;
    max-width: 1440px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    flex-direction: column;
    @include media("<769px") {
      max-width: none;
    }

    .logo {
      width: 300px;
      height: 76px;
      img {
        max-width: 100%;
      }
      @include media("<=1024px") {
        margin-top: 50px;
      }
    }
    .menuIcon {
      display: block;
      position: absolute;
      top: 20px;
      right: 30px;
      @include media(">1024px") {
        display: none;
      }
    }
  }
}
