@import "../breakpoint";
.navItem {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 56px;
  p {
    margin-right: 25px;
    color: white;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.5px;
    text-align: right;
  }
  .icon {
    background-color: white;
    height: 62px;
    width: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    border-radius: 23px;
  }
  &:hover {
    p {
      font-weight: 700;
    }
    .icon {
      box-shadow: 0px 7px 14px #00ae4d;
      .hover {
        fill: #00ae4d;
      }
    }
  }
}

.navbar {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0;
  position: absolute;
  top: 20%;
  right: 100px;
  a {
    text-decoration: none;
  }
  @include media("<=1024px") {
    display: none;
  }
}
