@import "../../breakpoint";

.about1 {
  background-color: #001a3a;
  padding-top: 75px;
  margin-top: -1px;
  .container {
    padding: 0;
    .content {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      .leftContent {
        width: 50%;
        .headingContainer {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 50px;
          .heading {
            color: #fff;
            margin-right: 30px;
            font-size: 36px;
            line-height: 44px;
            font-weight: 500;
          }
          .imageContainer {
            width: 64px;
            height: 42px;
            img {
              max-width: 100%;
            }
          }
        }
        .paragraph {
          p {
            color: #fff;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            text-align: justify;
          }
        }
        @include media("<=768px") {
          width: 100%;
          padding: 20px;
        }
      }
      .rightImage {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img {
          max-width: 100%;
          margin-bottom: 30px;
          border-radius: 25px;
        }
        p {
          color: #fff;
          font-style: normal;
          font-weight: 800;
          font-size: 16px;
          line-height: 24px;
        }
        @include media("<=768px") {
          width: 100%;
          padding: 20px;
        }
      }
      @include media("<=768px") {
        flex-direction: column;
      }
    }
  }
}
