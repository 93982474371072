@import "../breakpoint";

.diseasescard {
  .container {
    padding: 40px 0px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: row;
    border-bottom: 1px solid #cccccc;
    .image {
      display: inline-block;
      text-align: left;
      width: 225px;
      height: 240px;
      max-width: 225px;
      max-height: 240px;
      overflow: hidden;
      border-radius: 20px;
      @include media("<768px") {
        width: 175px;
        height: 175px;
        padding-right: 10px;
      }
      @include media("<577px") {
        min-width: 125px;
        min-height: 125px;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 20px;
      }
    }
    .content {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-start;
      max-width: 1000px;
      min-height: 240px;
      height: 100%;
      @include media("<1400px") {
        max-width: 800px;
      }
      @include media("<1200px") {
        max-width: 700px;
      }
      @include media("<992px") {
        max-width: 500px;
      }
      @include media("<768px") {
        max-width: 400px;
        min-height: auto;
        padding-left: 10px;
      }
      h4 {
        font-size: 20px;
        line-height: 24px;
        font-weight: 800;
        color: #000000;
        margin-bottom: 16px;
      }
      p {
        font-size: 16px;
        line-height: 28px;
        color: #000000;
        margin: 0;
      }
      a {
        margin-top: auto;
        text-decoration: none;
        color: #0091ff;
        line-height: 20px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        visibility: hidden;
        @include media("<=576px") {
          padding-left: 10px;
        }
      }
    }
    @include media("<768px") {
      justify-content: center;
    }
  }
  &:last-child {
    .container {
      border-bottom: 1px solid transparent;
    }
  }
}
