@import "../../breakpoint";

.bottom {
  background-color: #cdf9ff;
  padding: 200px 0px;
  position: relative;
  margin-top: -1px;
  @include media("<=1024px") {
    padding: 150px 0px;
  }
  @include media("<=768px") {
    padding: 100px 0px;
  }
  @include media("<=576px") {
    padding: 75px 0px;
  }
  .bottom_blue {
    position: absolute;
    top: 0;
    left: 0;
  }
  .container {
    max-width: 1440px;
    .blue_coral_bottom {
      position: absolute;
      bottom: 0;
      left: 200px;
      img {
        max-width: 100%;
      }
      @include media("<=1024px") {
        width: 150px;
        height: 150px;
        left: 50px;
      }
      @include media("<=768px") {
        width: 100px;
        height: 100px;
        left: 25px;
      }
      @include media("<=576px") {
        width: 75px;
        height: 75px;
        left: 10px;
      }
    }
    .right_coral_bottom {
      position: absolute;
      bottom: 0px;
      right: 60px;
      img {
        max-width: 100%;
        max-height: 100%;
      }
      @include media("<=1024px") {
        width: 150px;
        height: 200px;
      }
      @include media("<=768px") {
        width: 100px;
        height: 125px;
        right: 25px;
        margin-top: auto;
      }
      @include media("<=576px") {
        width: 75px;
        height: 75px;
        right: 10px;
      }
    }
    .semi_circle_bottom {
      position: absolute;
      bottom: 0;
      left: 600px;
      img {
        max-width: 100%;
      }
      @include media("<=1024px") {
        width: 75px;
        height: 75px;
        left: 400px;
        display: inline-flex;
        justify-content: center;
        align-items: flex-end;
      }
      @include media("<=600px") {
        width: 60px;
        height: 60px;
        left: 400px;
      }
      @include media("<=576px") {
        display: none;
      }
    }
    .small_circle_bottom {
      position: absolute;
      bottom: 70px;
      left: 700px;
      img {
        max-width: 100%;
      }
      @include media("<=1024px") {
        left: 450px;
      }
      @include media("<=576px") {
        display: none;
      }
    }
    .star_bottom {
      position: absolute;
      left: 400px;
      bottom: 30px;
      img {
        max-width: 100%;
      }
      @include media("<=1024px") {
        width: 75px;
        height: 75px;
        left: 300px;
      }
      @include media("<=576px") {
        width: 50px;
        height: 50px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
    .leftFish {
      width: 64px;
      position: absolute;
      top: 0;
      img {
        max-width: 100%;
      }
    }
  }
}
