@import "../../breakpoint";

.home4 {
  position: relative;
  background-color: #001a3a;
  margin-top: -1px;
  .container {
    .inner-container {
      padding: 65px 0px;
      border-top: 1px solid #ffffff;
      border-bottom: 1px solid #ffffff;
      width: 100%;
      margin: 0 auto;
      .title {
        display: flex;
        align-items: center;
        h1 {
          font-family: Montserrat;
          font-weight: 800;
          font-size: 36px;
          line-height: 28px;
          color: #fff;
          margin: 0;
          @include media("<=768px") {
            font-size: 30px;
          }
          @include media("<=576px") {
            font-size: 24px;
          }
        }
        .image {
          width: 36px;
          margin-left: 28px;
          img {
            width: 100%;
          }
          @include media("<=576px") {
            width: 24px;
            margin-left: 22px;
          }
        }
      }
      .live_price {
        margin-top: 36px;
        .title_card {
          display: flex;
          align-items: center;
          padding-bottom: 20px;
          .image {
            width: 36px;
            img {
              max-width: 100%;
            }
            margin-right: 15px;
          }
          .title_desc {
            margin: 0;
            color: #fff;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 28px;
          }
        }
      }
      .data_number {
        .text {
          color: #fff;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 28px;
          margin-right: 10px;
        }
        a {
          cursor: pointer;
          text-decoration: none;
          color: #0091ff;
          margin-right: 10px;
          @include media("<=420px") {
            display: block;
            margin-top: 5px;
          }
        }
      }
    }
  }
}
