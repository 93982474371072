@import "../../breakpoint";

.product_item {
  .container {
    padding: 0;
    display: grid;
    grid-template-areas:
      "image content"
      "image sizes";
    padding: 40px 0px;
    border-bottom: 1px solid #cccccc;
    .image {
      grid-area: image;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      max-width: 230px;
      @include media("<1200px") {
        max-width: 175px;
        margin-right: 20px;
      }
      .main_image {
        margin-bottom: 15px;
        text-align: center;
        width: 100%;
        img {
          max-width: 100%;
          border-radius: 25px;
        }
      }
      .sub_image {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .image1 {
          width: 50px;
          height: 50px;
          cursor: pointer;
          img {
            max-width: 100%;
            border-radius: 5px;
          }
          @include media("<1200px") {
            width: 40px;
            height: 40px;
          }
        }

        @include media("<=768px") {
          display: none;
        }
      }
    }
    .content {
      grid-area: content;
      @include media("<1200px") {
        margin-left: 20px;
      }
      h4 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        span {
          margin-left: 10px;
          img {
            @include media("<=450px") {
              transform: scale(0.9);
            }
          }
        }
        @include media("<=768px") {
          font-size: 16px;
          line-height: 20px;
        }
        @include media("<=450px") {
          font-size: 14px;
          line-height: 18px;
        }
      }
      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
        max-width: 800px;
        margin-bottom: 40px;
        text-align: justify;
        @include media("<=768px") {
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 10px;
        }
      }
    }
    .sizes {
      grid-area: sizes;
      margin-top: auto;
      @include media("<1200px") {
        margin-left: 20px;
      }
      @include media("<=768px") {
        margin-left: 0px;
      }
      h5 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 25px;
      }
      .react-tabs__tab {
        border: 1px solid #001a3a;
        cursor: pointer;
        margin-right: 20px;
        &.custom_tab {
          border-radius: 32px;
          @include media("<=439px") {
            font-size: 14px;
            min-width: 80px;
          }
          @include media("<380px") {
            margin-right: 10px;
          }
        }
      }
      .protein_variation {
        margin: 0;
        span {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          margin-right: 10px;
        }
        .view {
          text-decoration: none;
          cursor: pointer;
          @include media("<=439px") {
            display: block;
            margin-top: 5px;
          }
        }
      }
    }
    @include media("<=768px") {
      grid-template-areas:
        "image content"
        "sizes sizes";
    }
  }
  &:last-child {
    .container {
      border-bottom: none;
    }
  }
}

// .size_container {
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   margin-bottom: 1rem;
//   .size {
//     padding: 10px 15px;
//     background: #fff;
//     border-radius: 32px;
//     font-family: Montserrat;
//     font-style: normal;
//     font-weight: 800;
//     font-size: 16px;
//     line-height: 20px;
//     color: #001a3a;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin-right: 25px;
//     border: 1px solid #001a3a;
//     cursor: pointer;
//     &.active {
//       background: #001a3a;
//       color: #ffffff;
//     }
//     @include media("<=768px") {
//       font-size: 14px;
//       line-height: 18px;
//     }
//     @include media("<=576px") {
//       font-size: 12px;
//       line-height: 16px;
//     }
//   }
// }
