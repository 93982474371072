@import "../../breakpoint";

.contact {
  background-color: #001a3a;
  padding: 150px 0px 275px;
  position: relative;
  margin-top: -1px;
  .container {
    padding: 0;
    position: relative;
    .inner-container {
      margin: 0 auto;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .content {
        width: 50%;
        .address {
          p {
            color: #fff;
            font-weight: 400;
            font-size: 20px;
            line-height: 36px;
          }
          .heading {
            font-size: 36px;
            font-weight: 800;
            line-height: 44px;
          }
          .phoneNumber {
            display: inline-flex;
            text-decoration: none;
            justify-content: center;
            align-items: center;
            margin-bottom: 1rem;
            span {
              width: 32px;
              img {
                max-width: 100%;
              }
            }
            p {
              margin: 0;
            }
          }
          margin-bottom: 100px;
          @include media("<=768px") {
            margin-bottom: 50px;
            padding: 30px;
          }
        }
        @include media("<=1024px") {
          width: 100%;
        }
      }
      .map {
        width: 50%;
        margin: 0 auto;
        text-align: right;
        @include media("<=1024px") {
          width: 100%;
          text-align: center;
          padding-bottom: 40px;
        }
        @include media("<=768px") {
          padding: 30px;
          svg {
            width: 350px;
            height: 295px;
          }
        }
        @include media("<=500px") {
          padding: 30px;
          svg {
            width: 300px;
            height: 253px;
          }
        }
      }
      @include media("<=1024px") {
        flex-direction: column-reverse;
      }
    }
    .leftFishContainer {
      position: absolute;
      bottom: -200px;
      left: 400px;
      z-index: 3;
      @include media("<=576px") {
        width: 120px;
        height: 70px;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      @include media("<=576px") {
        padding: 10px;
        right: 0px;
        left: auto;
      }

      .leftFishSmall {
        width: 64px;
        position: absolute;
        bottom: -60px;
        img {
          max-width: 100%;
        }
        @include media("<=576px") {
          width: 48px;
          height: 48px;
        }
      }
    }
  }
  @include media("<=768px") {
    padding-top: 100px;
  }
  @include media("<=576px") {
    padding-top: 75px;
  }

  .custom-shape-divider-bottom-1632391273 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-bottom-1632391273 svg {
    position: relative;
    display: block;
    width: calc(140% + 1.3px);
    height: 240px;
    @include media("<=600px") {
      height: 140px;
    }
  }

  .custom-shape-divider-bottom-1632391273 .shape-fill {
    fill: #cdf9ff;
  }
}
