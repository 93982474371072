@import "../breakpoint";

.footer {
  background-color: #cdf9ff;
  padding: 150px 0px 200px;
  position: relative;
  .container {
    max-width: 1440px;
    .blue_coral_bottom {
      position: absolute;
      bottom: 0;
      left: 200px;

      img {
        max-width: 100%;
      }
    }
    .right_coral_bottom {
      position: absolute;
      bottom: 0px;
      right: 60px;
      img {
        max-width: 100%;
      }
    }
    .semi_circle_bottom {
      position: absolute;
      bottom: 0;
      left: 600px;
    }
    .small_circle_bottom {
      position: absolute;
      bottom: 70px;
      left: 700px;
    }
    .star_bottom {
      position: absolute;
      left: 400px;
      bottom: 30px;
    }
    .rightFishContainer {
      position: absolute;
      width: 150px;
      height: 100px;
      left: 250px;
      right: 0;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: auto;
      margin-right: auto;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .rightSmallFishContainer {
      position: absolute;
      width: 65px;
      height: 40px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  @include media("<=768px") {
    display: none;
  }
}
