@import "../breakpoint";

.contact_common {
  background-color: #cdf9ff;
  padding: 50px 0px 0px;
  .container {
    padding: 0;
    padding-top: 75px;
    .inner-container {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .content {
        width: 50%;
        .address {
          p {
            color: #001a3a;
            font-weight: 400;
            font-size: 20px;
            line-height: 36px;
            @include media("<=576px") {
              font-size: 18px;
            }
            @include media("<=420px") {
              font-size: 16px;
            }
          }
          .heading {
            font-size: 36px;
            font-weight: 800;
            line-height: 44px;
            @include media("<=576px") {
              font-size: 30px;
            }
            @include media("<=420px") {
              font-size: 24px;
            }
          }
          .phoneNumber {
            display: inline-flex;
            text-decoration: none;
            justify-content: center;
            align-items: center;
            margin-bottom: 1rem;

            p {
              margin: 0;
            }
          }
          margin-bottom: 100px;
          @include media("<=768px") {
            margin-bottom: 50px;
          }
          @include media("<=576px") {
            margin-bottom: 30px;
          }
          @include media("<=420px") {
            margin-bottom: 20px;
          }
        }
        @include media("<=768px") {
          width: 100%;
          padding: 20px;
        }
      }
      .map {
        width: 50%;
        text-align: right;
        svg {
          path:first-child {
            fill: #001a3a;
          }
        }

        @include media("<=1024px") {
          width: 100%;
          text-align: center;
          padding-bottom: 50px;
          svg {
            width: 350px;
            height: 295px;
          }
        }
        @include media("<=500px") {
          svg {
            width: 300px;
            height: 253px;
          }
        }
      }
      @include media("<=1024px") {
        flex-direction: column-reverse;
      }
    }
  }
}
