@import "../../breakpoint";

.home3 {
  position: relative;
  background-color: #001a3a;
  margin-top: -1px;
  .container {
    padding: 0;
    padding-top: 150px;
    position: relative;
    .inner-container {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 40px;
      .image-container {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .image_inner_container {
          min-height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          .image1 {
            padding-bottom: 15px;
            // @include media("<=1200px") {
            //   max-width: 200px;
            //   max-height: 100%;
            // }
            @include media("<=576px") {
              max-width: 150px;
            }
            img {
              max-width: 100%;
              max-height: 100%;
              border-radius: 30px;
            }
          }
          .image3 {
            padding-top: 15px;
            // @include media("<=1200px") {
            //   max-width: 200px;
            // }
            @include media("<=576px") {
              max-width: 150px;
            }
            img {
              max-width: 100%;
              max-height: 100%;
              border-radius: 30px;
            }
          }
        }
        .image2 {
          padding-left: 30px;
          @include media("<=576px") {
            max-width: 180px;
          }
          img {
            max-width: 100%;
            border-radius: 30px;
          }
        }

        @include media("<=1024px") {
          width: 100%;
        }
      }
      .content {
        width: 50%;
        max-width: 450px;
        h2 {
          font-weight: 800;
          font-size: 52px;
          color: #fff;
          @include media("<=768px") {
            font-size: 40px;
          }
          @include media("<=567px") {
            font-size: 30px;
          }
          @include media("<=400px") {
            font-size: 24px;
          }
        }
        p {
          color: #fff;
          font-size: 20px;
          line-height: 28px;
          font-weight: 500;
          margin-bottom: 35px;
          text-align: left;
          @include media("<=768px") {
            font-size: 16px;
          }
          @include media("<=567px") {
            font-size: 14px;
          }
        }
        .btn {
          padding: 10px 20px;
          cursor: pointer;
          border-radius: 32px;
          background-color: #fff;
          font-size: 24px;
          font-weight: 500;
          a {
            color: #001a3a;
            text-decoration: none;
          }
          @include media("<=768px") {
            font-size: 20px;
          }
          @include media("<=567px") {
            font-size: 16px;
          }
          &:hover {
            a {
              color: #00ae4d;
            }
            box-shadow: 0px 7px 14px #00ae4d;
          }
        }
        @include media("<=1024px") {
          width: 100%;
          padding-top: 75px;
        }
      }
      @include media("<1200px") {
        justify-content: space-between;
      }
      @include media("<=1024px") {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    #FishContainer3 {
      position: absolute;
      top: 0;
      right: 400px;
      .bigFishContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        opacity: 1;
        .bigFish {
          width: 150px;
          height: 100px;
          margin-right: 60px;
          img {
            width: 100%;
          }
          @include media("<=576px") {
            width: 100px;
            height: 66px;
            margin-right: 30px;
          }
        }
        .smallFish1 {
          width: 64px;
          height: 42px;

          img {
            width: 100%;
          }
          @include media("<=576px") {
            width: 50px;
            height: 33px;
          }
        }
      }
      .smallFishContainer {
        z-index: 1;
        width: 64px;
        height: 42px;
        margin: 0 auto;
        img {
          width: 100%;
        }
        @include media("<=576px") {
          width: 50px;
          height: 33px;
        }
      }
    }
    @include media("<=576px") {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
