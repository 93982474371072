@import "../../breakpoint";

.about3 {
  background-color: #cdf9ff;
  position: relative;
  margin-top: -1px;
  .green-coral {
    position: absolute;
    left: 0;
    top: 0;
  }
  .container {
    max-width: 1440px;
    padding: 0;

    .content {
      padding-top: 100px;
      padding-bottom: 100px;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .box {
        .number {
          font-weight: 800;
          font-size: 36px;
          line-height: 44px;
          margin-bottom: 5px;
          @include media("<=768px") {
            font-size: 24px;
          }
        }
        p {
          text-align: center;
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          @include media("<=768px") {
            font-size: 20px;
          }
        }
        @include media("<=768px") {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
      }
      @include media("<=768px") {
        flex-wrap: wrap;
        padding-top: 50px;
        padding-bottom: 50px;
      }
    }
  }
}
