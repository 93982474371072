@import "../../breakpoint";

.about2 {
  background-color: #001a3a;
  position: relative;
  padding: 150px 0px;
  margin-top: -1px;
  @include media("<=576px") {
    padding: 100px 0px;
  }
  .container {
    padding: 0;
    .video {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    padding-bottom: 170px;
  }
  .custom-shape-divider-bottom-1632391273 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-bottom-1632391273 svg {
    position: relative;
    display: block;
    width: calc(140% + 1.3px);
    height: 240px;
    @include media("<=600px") {
      height: 140px;
    }
  }

  .custom-shape-divider-bottom-1632391273 .shape-fill {
    fill: #cdf9ff;
  }
}
