@import "../node_modules/bootstrap/scss/bootstrap";
@import "./components/breakpoint";

body {
  font-family: "Montserrat", sans-serif;
  cursor: url("./assets/cork_cursor2.png"), auto;
}

.showMenu {
  display: block;
  z-index: 5;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  color: white;
  background: #001a3a;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in;
  .header_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    .logo_menu {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
      width: 300px;
      height: 76px;
      @include media("<=600px") {
        width: 200px;
      }
      img {
        max-width: 100%;
        max-height: auto;
      }
    }
    .closeIcon {
      margin-right: 20px;
    }
  }
  .showMenu_navMenu {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    .showMenu_navItem {
      display: inline-block;
      text-decoration: none;
      padding: 20px 0px;
      color: #fff;
      font-size: 16px;
    }
  }
  &.showMenu_active {
    transform: translateX(0%);
  }
  .contact_menuBar {
    margin-left: 20px;
    padding-bottom: 30px;
    .head {
      margin: 0;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 15px;
    }
    .email {
      margin: 0;
      font-size: 14px;
      margin-bottom: 12px;
    }
    .number {
      display: inline-flex;
      margin: 0;
      color: #0091ff;
      text-decoration: none;
      font-size: 14px;
      margin-bottom: 12px;
      align-items: center;
      span {
        width: 32px;
        margin-right: 10px;
        img {
          max-width: 100%;
        }
      }
      p {
        margin: 0;
      }
    }
    .address {
      margin: 0;
      font-size: 14px;
      margin-bottom: 12px;
    }
  }
  .image_menu {
    display: flex;
    justify-content: space-between;
    .blue {
      margin-left: 20px;
    }
    .green {
      margin-right: 20px;
      align-self: flex-end;
    }
  }
}
