@import "../../breakpoint";

.home2 {
  width: 100%;
  position: relative;
  background-color: #cdf9ff;
  margin-top: -1px;
  .container {
    position: relative;
    padding: 100px 0px 150px;
    max-width: 1440px;
    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
    .inner-container {
      max-width: 1056px;
      width: 100%;
      margin: 0 auto;
      padding-bottom: 175px;
      .card-container {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        padding-bottom: 30px;
        @include media("<=1024px") {
          padding: 0px 20px;
        }
      }
      .content {
        padding: 10px 0px;
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
        color: #054089;
        margin: 0;
        text-align: left;
        @include media("<=1024px") {
          font-size: 16px;
          padding: 20px 20px;
        }
      }
      .content2 {
        padding: 10px 0px;
        text-align: center;
        font-weight: 700;
        color: #054089;
        font-size: 24px;
        margin: 0;
        @include media("<=768px") {
          font-size: 22px;
        }
        @include media("<=576px") {
          font-size: 20px;
        }
        @include media("<=456px") {
          font-size: 18px;
        }
        @include media("<=412px") {
          font-size: 16px;
        }
      }
      @include media(">=576px") {
        max-width: 540px;
      }
      @include media(">=768px") {
        max-width: 720px;
      }
      @include media(">=992px") {
        max-width: 960px;
      }
      @include media(">=1200px") {
        max-width: 1056px;
      }
      @include media("<=768px") {
        padding-bottom: 100px;
      }
    }
    .topCircle {
      position: absolute;
      top: 0;
      left: 0;
    }
    .bigcircle {
      @include media("<=1024px") {
        display: none;
      }
      @include media("<=1200px") {
        width: 85px;
        height: 85px;
      }
    }
    .smallcircle {
      top: 150px;
      @include media("<=1024px") {
        display: none;
      }
    }
    .bigcircle2 {
      right: 0px;
      bottom: 450px;
      height: 75px;
      width: 75px;
      top: auto;
      left: auto;
      @include media("<=1024px") {
        display: none;
      }
    }
  }
  .custom-shape-divider-bottom-1632391273 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-bottom-1632391273 svg {
    position: relative;
    display: block;
    width: calc(140% + 1.3px);
    height: 240px;
    @include media("<=600px") {
      height: 140px;
    }
  }

  .custom-shape-divider-bottom-1632391273 .shape-fill {
    fill: #001a3a;
  }

  .blue-coral {
    position: absolute;
    bottom: 0px;
    left: 132px;
    img {
      max-width: 100%;
    }
    @include media("<=768px") {
      left: 0px;
      margin-left: 10px;
      width: 150px;
      height: 150px;
    }
    @include media("<=576px") {
      left: 0px;
      margin-left: 10px;
      width: 100px;
      height: 100px;
    }
  }

  .right-coral {
    position: absolute;
    bottom: 50px;
    right: 0px;

    img {
      max-width: 100%;
    }
    @include media("<=768px") {
      right: 0px;
      bottom: 90px;
      margin-right: 10px;
      width: 150px;
      height: 150px;
    }
    @include media("<=576px") {
      right: 0px;
      bottom: 90px;
      margin-right: 10px;
      width: 100px;
      height: 100px;
    }
  }
}

.card1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .image {
    text-align: center;
    width: 150px;
    margin-bottom: 80px;
    height: 90px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
    @include media("<=1200px") {
      width: 125px;
      height: 75px;
      margin-bottom: 60px;
    }
    @include media("<=992px") {
      width: 100px;
      height: 60px;
      margin-bottom: 40px;
    }
    @include media("<=768px") {
      width: 80px;
      height: 50px;
      margin-bottom: 20px;
    }
    @include media("<=576px") {
      width: 60px;
      height: 40px;
    }
  }
  .title {
    background-color: #fff;
    border-radius: 16px;
    padding: 15px 80px;
    &.active {
      box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.2);
    }
    p {
      color: #000;
      font-size: 45px;
      text-align: center;
      font-weight: 800;
      margin: 0;
      @include media("<=1200px") {
        font-size: 32px;
        margin: 0;
      }
      @include media("<=992px") {
        font-size: 18px;
      }
      @include media("<=768px") {
        font-size: 16px;
      }
      @include media("<=576px") {
        font-size: 14px;
      }
    }
    @include media("<=1200px") {
      padding: 10px 60px;
    }
    @include media("<=992px") {
      padding: 8px 50px;
    }
    @include media("<=768px") {
      padding: 7px 40px;
    }
    @include media("<=576px") {
      padding: 5px 25px;
    }
  }
}

.card2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .image {
    width: 150px;
    height: 90px;
    margin-bottom: 80px;
    position: relative;
    img {
      max-width: 100%;
      max-height: 100%;
    }
    @include media("<=1200px") {
      width: 125px;
      height: 75px;
      margin-bottom: 60px;
    }
    @include media("<=992px") {
      width: 100px;
      height: 60px;
      margin-bottom: 40px;
    }
    @include media("<=768px") {
      width: 80px;
      height: 50px;
      margin-bottom: 20px;
    }
    @include media("<=576px") {
      width: 60px;
      height: 40px;
    }
    .bigfishcontainer {
      width: 100%;
      height: 100%;
      position: relative;
      .bigFish1 {
        width: 75px;
        @include media("<=1200px") {
          width: 60px;
        }
        @include media("<=992px") {
          width: 50px;
        }
        @include media("<=768px") {
          width: 40px;
        }
        @include media("<=576px") {
          width: 30px;
        }
      }
      .bigFish2 {
        position: absolute;
        width: 75px;
        bottom: 0;
        right: 0;
        @include media("<=1200px") {
          width: 60px;
        }
        @include media("<=992px") {
          width: 50px;
        }
        @include media("<=768px") {
          width: 40px;
        }
        @include media("<=576px") {
          width: 30px;
        }
      }
    }

    #smallFish1 {
      opacity: 0;
    }
  }
  .title {
    background-color: #fff;
    border-radius: 16px;
    padding: 15px 80px;
    &.active {
      box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.2);
    }
    p {
      color: #000;
      font-size: 45px;
      text-align: center;
      font-weight: 800;
      margin: 0;
      @include media("<=1200px") {
        font-size: 32px;
        margin: 0;
      }
      @include media("<=992px") {
        font-size: 18px;
      }
      @include media("<=768px") {
        font-size: 16px;
      }
      @include media("<=576px") {
        font-size: 14px;
      }
    }
    @include media("<=1200px") {
      padding: 10px 60px;
    }
    @include media("<=992px") {
      padding: 8px 50px;
    }
    @include media("<=768px") {
      padding: 7px 40px;
    }
    @include media("<=576px") {
      padding: 5px 25px;
    }
  }
}

.card3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .image {
    width: 150px;
    height: 90px;
    margin-bottom: 80px;
    position: relative;
    .image1 {
      width: 100%;
      height: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 1;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .image2 {
      width: 100%;
      height: 100%;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    @include media("<=1200px") {
      width: 125px;
      height: 75px;
      margin-bottom: 60px;
    }
    @include media("<=992px") {
      width: 100px;
      height: 60px;
      margin-bottom: 40px;
    }
    @include media("<=768px") {
      width: 80px;
      height: 50px;
      margin-bottom: 20px;
    }
    @include media("<=576px") {
      width: 60px;
      height: 40px;
    }
  }
  .title {
    background-color: #fff;
    border-radius: 16px;
    padding: 15px 80px;
    &.active {
      box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.2);
    }
    p {
      color: #000;
      font-size: 45px;
      text-align: center;
      font-weight: 800;
      margin: 0;
      @include media("<=1200px") {
        font-size: 32px;
        margin: 0;
      }
      @include media("<=992px") {
        font-size: 18px;
      }
      @include media("<=768px") {
        font-size: 16px;
      }
      @include media("<=576px") {
        font-size: 14px;
      }
    }
    @include media("<=1200px") {
      padding: 10px 60px;
    }
    @include media("<=992px") {
      padding: 8px 50px;
    }
    @include media("<=768px") {
      padding: 7px 40px;
    }
    @include media("<=576px") {
      padding: 5px 25px;
    }
  }
}

.FishContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  .fish1 {
    width: 48px;
    position: absolute;
    top: 0px;
    left: 20px;
    img {
      max-width: 100%;
    }
    @include media("<=1200px") {
      width: 40px;
      left: 15px;
    }
    @include media("<=992px") {
      width: 32px;
      left: 10px;
    }
    @include media("<=768px") {
      width: 24px;
      left: 0px;
    }
    @include media("<=576px") {
      width: 16px;
    }
  }
  .fish2 {
    width: 48px;
    position: absolute;
    top: 0px;
    right: 20px;
    img {
      max-width: 100%;
    }
    @include media("<=1200px") {
      width: 40px;
      right: 15px;
    }
    @include media("<=992px") {
      width: 32px;
      right: 10px;
    }
    @include media("<=768px") {
      width: 24px;
      right: 0px;
    }
    @include media("<=576px") {
      width: 16px;
    }
  }
  .fish3 {
    width: 48px;
    position: absolute;
    bottom: 0px;
    right: 10px;
    img {
      max-width: 100%;
    }
    @include media("<=1200px") {
      width: 40px;
      right: 5px;
    }
    @include media("<=992px") {
      width: 32px;
    }
    @include media("<=768px") {
      width: 24px;
      right: 0px;
    }
    @include media("<=576px") {
      width: 16px;
    }
  }
  .fish4 {
    width: 48px;
    position: absolute;
    bottom: 0px;
    left: 10px;
    img {
      max-width: 100%;
    }
    @include media("<=1200px") {
      width: 40px;
      left: 5px;
    }
    @include media("<=992px") {
      width: 32px;
    }
    @include media("<=768px") {
      width: 24px;
      left: 0px;
    }
    @include media("<=576px") {
      width: 16px;
    }
  }
  .fish5 {
    width: 48px;
    position: absolute;
    top: 35%;
    left: 35%;
    img {
      max-width: 100%;
    }
    @include media("<=1200px") {
      width: 40px;
    }
    @include media("<=992px") {
      width: 32px;
    }
    @include media("<=768px") {
      width: 24px;
    }
    @include media("<=576px") {
      width: 16px;
    }
  }
  @include media("<=1200px") {
    width: 125px;
    height: 75px;
  }
  @include media("<=992px") {
    width: 100px;
    height: 60px;
  }
  @include media("<=768px") {
    width: 80px;
    height: 50px;
  }
  @include media("<=576px") {
    width: 60px;
    height: 40px;
  }
}
