@import "../../breakpoint";

.container {
  padding: 0;
  padding-top: 60px;
  padding-bottom: 50px;
  .react-tabs__tab-list {
    border-bottom: none;
    .react-tabs__tab {
      bottom: 10px;
      min-width: 100px;
      height: auto;
      text-align: center;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      border: 1px solid #266ec8;
      margin-right: 20px;
      &.react-tabs__tab--selected {
        background-color: #001a3a;
        color: #fff;
        border: 1px solid transparent;
        &:hover {
          border: 1px solid #266ec8;
        }
      }
      @include media("<447px") {
        min-width: 0;
        font-size: 14px;
        padding: 5px 10px;
        height: auto;
        margin-right: 12px;
      }
    }
  }
  @include media("<=576px") {
    padding-left: 20px;
    padding-right: 20px;
  }
}
